

.NavTextOrientation {
    font: bold 1.2rem Sans-Serif;
    letter-spacing: .3rem;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    writing-mode: tb-rl;
}

.NavBar {
    padding-top: 2.5rem;
    position:fixed;
    width: 6rem;
    height: 100rem;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    z-index: 2;
}

.NavBar:before{
    position:absolute;
    z-index:-1;
    top:0;
    left:30%;
    width:40%;
    height:100%;
    content:"";
    background-color:white;
}